<template>
    <!-- num_process -->
    <div class="num_process">
        <div class="overview">
            <div class="text">
                <h1>가상계좌 결제 서비스</h1>
                <p>
                    입금자, 입금일 등에 대한 주문자 별 상세 데이터가 필요한데 일반 계좌 이체로는 파악하기 번거롭습니다.<br /><br />
                    이를 대비하여 구매자(고객)에게 고유의 입금 전용 계좌를 발급해주고, 구매자가 지정한 은행에 상품 및
                    서비스 대금을 입금하여 수납업무의 효율성을 높여주는 결제 서비스입니다.
                </p>
            </div>
            <div class="img_box">
                <img src="@/assets/image/pay/virtual/overview.png" alt="쇼핑몰 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="@/assets/image/pay/virtual/icon_strong_01.svg" alt="상품특징" />
                <p>효율성</p>
                <p>입금 세부내역(입금자, 입금일, 금액, 내용) 등을 실시간으로 확인하여 수납업무의 효율성을 높여줍니다.</p>
            </li>
            <li>
                <img src="@/assets/image/pay/virtual/icon_strong_02.svg" alt="상품특징" />
                <p>안정성</p>
                <p>입금금액 또는 입금자 이름이 상이할시 검수 프로그램으로 결제오류를 방지하여 안정적인 서비스를 제공합니다</p>
            </li>
            <li>
                <img src="@/assets/image/pay/virtual/icon_strong_03.svg" alt="상품특징" />
                <p>편리함</p>
                <p>일괄 정산을 통해 재무관리의 편리성을 제공합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="pay_structure">
                <h1>결제 프로세스</h1>
                <div class="img_box">
                    <img src="@/assets/image/pay/virtual/pay_structure.png" alt="결제 프로세스" class="m_hidden" />
                    <img src="@/assets/image/pay/virtual/m_pay_structure.png" alt="결제 프로세스" class="m_show" />
                </div>
            </div>
            <div class="service_process">
                <h1>가상계좌 결제방법</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3>1</h3>
                            <p>고객이 입금은행<br />선택</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>2</h3>
                            <p>고객 전용<br />가상계좌번호 발급</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>3</h3>
                            <p>고객이 부여된 가상<br />계좌번호로 입금</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>4</h3>
                            <p>결제 완료</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Virtual'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>