<template>
    <div class="num_process">
        <div class="overview">
            <div class="text">
                <h1>SMS/URL 결제</h1>
                <p>
                    개인정보 유출 사례가 빈번하게 발생하는 만큼 이에 대비한 결제 서비스입니다.<br /><br />
                    정보 노출에 민감한 고객분들께 URL 링크를 SMS로 전송하여 고객이 직접 URL 접속 및 카드정보 입력을 통해
                    카드 승인을 요청하는 서비스이며, 카드정보는 암호화되어 PG서버에 저장됩니다.
                </p>
            </div>
            <div class="img_box">
                <img src="~@/assets/image/pay/sms/overview.png" alt="쇼핑몰 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="~@/assets/image/pay/sms/icon_strong_01.svg" alt="상품특징" />
                <p>안전함</p>
                <p>카드 정보를 제 3자에게 노출하지 않고 고객이 직접 SMS문자 링크를 클릭하여 결제가 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/sms/icon_strong_02.svg" alt="상품특징" />
                <p>효율성</p>
                <p>SMS/URL결제 내역을 관리할 수 있는 매출관리시스템을 제공합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/sms/icon_strong_03.svg" alt="상품특징" />
                <p>다양함</p>
                <p>블로그, SNS 등 모든 판매 형태에 대해 결제서비스 제공이 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/sms/icon_strong_04.svg" alt="상품특징" />
                <p>저렴함</p>
                <p>별도 장비구매 없이 무료로 도입이 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/sms/icon_strong_05.svg" alt="상품특징" />
                <p>편리함</p>
                <p>학원 수강료 등 서비스 대금에 대해 구두 요청이 아닌 자동 SMS 결제 요청으로 편리한 수금이 가능합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="use_case">
                <h1>이용사례</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3><i>A</i>학원 업체</h3>
                            <p>고객님께 매월 수강료 요청하기 번거로웠는데 SMS결제를 도입하고 편리해졌어요.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>B</i>개발 업체</h3>
                            <p>수금 시 직접 방문하여 결제를 진행했는데 문자발송만으로 결제가되서 너무 편했습니다.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>C</i>블로거</h3>
                            <p>쇼핑몰이 없어서 카드결제를 어렵게 받아야 했는데 문자발송으로 결제를 할 수 있어서 너무 좋았습니다.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3><i>D</i>주택관리 업체</h3>
                            <p>관리 가구들에게 미납된 수수료를 하나씩 전화해서 요청하기 번거로웠는데 문자결제도입으로 한번에 해결되었습니다.</p>
                        </div>
                    </li>
                </ul>
                <div></div>
            </div>
            <div class="service_process">
                <h1>SMS/URL 결제 프로세스</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3>1</h3>
                            <p>결제 WEB/APP<br />사이트 접속 및 로그인</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>2</h3>
                            <p>고객 SMS 결제<br />요청 문자 발송</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>3</h3>
                            <p>고객 카드정보 입력</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>4</h3>
                            <p>결제 완료</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'SMS'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>