<template>
    <div>
        <div class="overview">
            <div class="text">
                <h1>선정산 서비스</h1>
                <p>
                    평균적으로 카드 승인 매출 발생 후 입금 주기는 영업일 기준 3일~14일이 소요됩니다.<br /><br />
                    해당 기간을 단축시켜 현금유동성을 증가시키기 위해 매출 발생일 당일 혹은 다음날 즉시 결제 받을 수
                    있도록 선정산 서비스를 제공합니다.
                </p>
            </div>
            <div class="img_box">
                <img src="@/assets/image/pay/calculate/overview.png" alt="쇼핑몰 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="@/assets/image/pay/calculate/icon_strong_01.svg" alt="상품특징" />
                <p>신속성</p>
                <p>3일~14일 후 입금 정산받는 금액을 매출일 당일 혹은 다음날 지급하여 현금유동성 확보가 가능합니다.</p>
            </li>
            <li>
                <img src="@/assets/image/pay/calculate/icon_strong_02.svg" alt="상품특징" />
                <p>정확성</p>
                <p>매출 발생 후 일괄 정산을 통해 한눈에 입금 확인 및 정확한 정산이 가능합니다.</p>
            </li>
            <li>
                <img src="@/assets/image/pay/calculate/icon_strong_03.svg" alt="상품특징" />
                <p>저렴함</p>
                <p>업계 최저 수준의 선정산 수수료 제공을 통해 부담 없이 이용 가능합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="pay_structure">
                <h1>선정산 프로세스</h1>
                <div class="img_box">
                    <img src="@/assets/image/pay/calculate/pay_structure.png" alt="결제 프로세스" class="m_hidden" />
                    <img src="@/assets/image/pay/calculate/m_pay_structure.png" alt="결제 프로세스" class="m_show" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Calculate'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>