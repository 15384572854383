<template>
    <div>
        <div class="overview">
            <div class="text">
                <h1>쇼핑몰 결제</h1>
                <p>
                    쇼핑몰 사업을 운영하실 때 방문객이 상품이나 컨텐츠를 구매하기 위해 신용카드로 결제하는 기능 제공은 필수적입니다.<br /><br />
                    고객님의 니즈에 맞춰 다양한 결제 수단(신용카드, 간편결제, 계좌이체 등)을 쇼핑몰이나 홈페이지에서
                    이용하실 수 있도록 전자결제 솔루션을 제공합니다.
                </p>
            </div>
            <div class="img_box">
                <img src="~@/assets/image/pay/shoppingmall/overview.png" alt="쇼핑몰 결제" />
            </div>
        </div>
        <ul class="pay_strength">
            <li>
                <img src="~@/assets/image/pay/shoppingmall/icon_strong_01.svg" alt="상품특징" />
                <p>간편함</p>
                <p>티온에서 제공하는 솔루션 계약을 통해 국내 전 카드사의 일시불, 할부 거래가 가능합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/shoppingmall/icon_strong_02.svg" alt="상품특징" />
                <p>저렴함</p>
                <p>티온은 고객님의 니즈에 맞춰 최저 수수료를 제공해드리기 위해 노력합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/shoppingmall/icon_strong_03.svg" alt="상품특징" />
                <p>효율성</p>
                <p>결제솔루션의 통합 정산관리시스템을 통해 효율적인 매출관리를 제공합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/shoppingmall/icon_strong_04.svg" alt="상품특징" />
                <p>다양함</p>
                <p>PC 및 모바일 다양한 환경에 맞는 솔루션을 제공합니다.</p>
            </li>
            <li>
                <img src="~@/assets/image/pay/shoppingmall/icon_strong_05.svg" alt="상품특징" />
                <p>안전함</p>
                <p>티온은 정식 라이선스를 보유한 솔루션만 제공하여 안정되고 빠른 시스템 및 정보보호에 최선을 다합니다.</p>
            </li>
        </ul>
        <div class="pay_bottom_content">
            <div class="pay_structure">
                <h1>결제 프로세스</h1>
                <div class="img_box">
                    <img src="~@/assets/image/pay/shoppingmall/pay_structure.png" alt="결제 프로세스" class="m_hidden" />
                    <img src="~@/assets/image/pay/shoppingmall/m_pay_structure.png" alt="결제 프로세스" class="m_show" />
                </div>
            </div>
            <div class="service_process">
                <h1>서비스 이용절차</h1>
                <ul>
                    <li>
                        <div class="wrapper">
                            <h3>
                                <span>1</span>
                                <span>티온 상담신청</span>
                            </h3>
                            <p>티온 직원 및 본사에 상담신청을 해주시면 티온에서 고객님에 필요한 서비스 확인 후 맞춤 형 솔루션 확인 및 계약을 도와드립니다.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>
                                <span>2</span>
                                <span>서비스 계약 및 시스템 적용</span>
                            </h3>
                            <p>사용 솔루션이 정해진 후 계약에 필요한 서류 안내 및 계약을 진행합니다. 쇼핑몰에 솔루션 API 연동을 진행하며 TEST를 진행합니다.</p>
                        </div>
                    </li>
                    <li>
                        <div class="wrapper">
                            <h3>
                                <span>3</span>
                                <span>서비스 이용</span>
                            </h3>
                            <p>서비스 개시하며 서비스를 이용하면서 문의사항이나 추가 필요사항은 티온에서 지속적으로 관리를 진행합니다.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Shoppingmall'
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_pay.css"></style>